import { Navigation, useActiveTenantUrl, ConfigType } from '@griegconnect/krakentools-react-kraken-app'
import { Navigate } from 'react-router-dom'
import { AccessDependencies } from '@app/permissions'
import { liveMapNavs } from './Map/mapNavs'
import { activitiesNavs } from './Activities/activitiesNavs'
import { geofencesNavs } from './Geofences/geofencesNavs'
// import { mooringPlanNavs } from './MooringPlan/mooringplannavs'

const RedirectRoute = () => {
  const { forPath } = useActiveTenantUrl()
  const redirectPath = forPath('/map')

  return <Navigate replace={true} to={redirectPath} />
}

export const navigation = (environment?: ConfigType['environment']): Navigation.Item<AccessDependencies>[] => [
  ...liveMapNavs,
  ...activitiesNavs,
  ...geofencesNavs,
  // ...(environment !== undefined && environment !== 'prod' ? mooringPlanNavs : []),
  {
    name: 'RedirectRoute',
    path: '/',
    page: <RedirectRoute />,
  },
]
